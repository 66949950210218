// @ts-nocheck
// Port of https://github.com/stacktracejs/stackframe/blob/master/stackframe.js.
// Rewritten to ES6 and removed UMD and CommonJS support.

function _isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function _capitalize(str) {
  return str.charAt(0).toUpperCase() + str.substring(1);
}

function _getter(p) {
  return function () {
    return this[p];
  };
}

var booleanProps = ["isConstructor", "isEval", "isNative", "isToplevel"];
var numericProps = ["columnNumber", "lineNumber"];
var stringProps = ["fileName", "functionName", "source"];
var arrayProps = ["args"];
var objectProps = ["evalOrigin"];

var props = booleanProps.concat(
  numericProps,
  stringProps,
  arrayProps,
  objectProps,
);

declare namespace StackFrame {
  export interface StackFrameOptions {
    isConstructor?: boolean;
    isEval?: boolean;
    isNative?: boolean;
    isToplevel?: boolean;
    columnNumber?: number;
    lineNumber?: number;
    fileName?: string;
    functionName?: string;
    source?: string;
    args?: any[];
    evalOrigin?: StackFrame;
  }
}

declare class StackFrame {
  constructor(obj: StackFrame.StackFrameOptions);

  args?: any[];
  getArgs(): any[] | undefined;
  setArgs(args: any[]): void;

  evalOrigin?: StackFrame;
  getEvalOrigin(): StackFrame | undefined;
  setEvalOrigin(stackframe: StackFrame): void;

  isConstructor?: boolean;
  getIsConstructor(): boolean | undefined;
  setIsConstructor(isConstructor: boolean): void;

  isEval?: boolean;
  getIsEval(): boolean | undefined;
  setIsEval(isEval: boolean): void;

  isNative?: boolean;
  getIsNative(): boolean | undefined;
  setIsNative(isNative: boolean): void;

  isToplevel?: boolean;
  getIsToplevel(): boolean | undefined;
  setIsToplevel(isToplevel: boolean): void;

  columnNumber?: number;
  getColumnNumber(): number | undefined;
  setColumnNumber(columnNumber: number): void;

  lineNumber?: number;
  getLineNumber(): number | undefined;
  setLineNumber(lineNumber: number): void;

  fileName?: string;
  getFileName(): string | undefined;
  setFileName(fileName: string): void;

  functionName?: string;
  getFunctionName(): string | undefined;
  setFunctionName(functionName: string): void;

  source?: string;
  getSource(): string | undefined;
  setSource(source: string): void;

  toString(): string;
}

function StackFrame(obj) {
  if (!obj) return;
  for (var i = 0; i < props.length; i++) {
    if (obj[props[i]] !== undefined) {
      this["set" + _capitalize(props[i])](obj[props[i]]);
    }
  }
}

StackFrame.prototype = {
  getArgs: function () {
    return this.args;
  },
  setArgs: function (v) {
    if (Object.prototype.toString.call(v) !== "[object Array]") {
      throw new TypeError("Args must be an Array");
    }
    this.args = v;
  },

  getEvalOrigin: function () {
    return this.evalOrigin;
  },
  setEvalOrigin: function (v) {
    if (v instanceof StackFrame) {
      this.evalOrigin = v;
    } else if (v instanceof Object) {
      this.evalOrigin = new StackFrame(v);
    } else {
      throw new TypeError("Eval Origin must be an Object or StackFrame");
    }
  },

  toString: function () {
    var fileName = this.getFileName() || "";
    var lineNumber = this.getLineNumber() || "";
    var columnNumber = this.getColumnNumber() || "";
    var functionName = this.getFunctionName() || "";
    if (this.getIsEval()) {
      if (fileName) {
        return (
          "[eval] (" + fileName + ":" + lineNumber + ":" + columnNumber + ")"
        );
      }
      return "[eval]:" + lineNumber + ":" + columnNumber;
    }
    if (functionName) {
      return (
        functionName +
        " (" +
        fileName +
        ":" +
        lineNumber +
        ":" +
        columnNumber +
        ")"
      );
    }
    return fileName + ":" + lineNumber + ":" + columnNumber;
  },
};

StackFrame.fromString = function StackFrame$$fromString(str) {
  var argsStartIndex = str.indexOf("(");
  var argsEndIndex = str.lastIndexOf(")");

  var functionName = str.substring(0, argsStartIndex);
  var args = str.substring(argsStartIndex + 1, argsEndIndex).split(",");
  var locationString = str.substring(argsEndIndex + 1);

  if (locationString.indexOf("@") === 0) {
    var parts = /@(.+?)(?::(\d+))?(?::(\d+))?$/.exec(locationString, "");
    var fileName = parts[1];
    var lineNumber = parts[2];
    var columnNumber = parts[3];
  }

  return new StackFrame({
    functionName: functionName,
    args: args || undefined,
    fileName: fileName,
    lineNumber: lineNumber || undefined,
    columnNumber: columnNumber || undefined,
  });
};

for (var i = 0; i < booleanProps.length; i++) {
  StackFrame.prototype["get" + _capitalize(booleanProps[i])] = _getter(
    booleanProps[i],
  );
  StackFrame.prototype["set" + _capitalize(booleanProps[i])] = (function (p) {
    return function (v) {
      this[p] = Boolean(v);
    };
  })(booleanProps[i]);
}

for (var j = 0; j < numericProps.length; j++) {
  StackFrame.prototype["get" + _capitalize(numericProps[j])] = _getter(
    numericProps[j],
  );
  StackFrame.prototype["set" + _capitalize(numericProps[j])] = (function (p) {
    return function (v) {
      if (!_isNumber(v)) {
        throw new TypeError(p + " must be a Number");
      }
      this[p] = Number(v);
    };
  })(numericProps[j]);
}

for (var k = 0; k < stringProps.length; k++) {
  StackFrame.prototype["get" + _capitalize(stringProps[k])] = _getter(
    stringProps[k],
  );
  StackFrame.prototype["set" + _capitalize(stringProps[k])] = (function (p) {
    return function (v) {
      this[p] = String(v);
    };
  })(stringProps[k]);
}

export default StackFrame;
